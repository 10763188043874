import React, { useEffect, useRef } from 'react';
import styles from './index.module.less';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import NameStarContent from '@/components/Common/NameStarContent';
import BaseLinearContent from '@/components/Common/BaseLinearContent';

const ReviewsContent = () => {
  const { t } = useTranslation();
  const containerRef = useRef(null);

  const dataList = [
    {
      name: 'Emily Johnson',
      desc: `As a busy mom, I don't have much time to spend at the salon. Hairpaca makes it so easy to explore new hairstyles from the comfort of my home. The virtual try-on feature is incredibly realistic, and I love receiving the weekly hairstyle highlights from my favorite influencers.`,
    },
    {
      name: 'Madison Smith',
      desc: `Hairpaca has become my go-to tool for hairstyle inspiration. I can easily follow the trendiest influencers and try out their looks virtually. It's so much fun to see how different styles look on me before actually visiting the salon. Highly recommend this to all fashion-forward ladies!`,
    },
    {
      name: 'Ethan Wilson',
      desc: `I'm usually pretty conservative with my hair, but Hairpaca has given me the confidence to try new looks. The virtual try-on is so accurate, and following the top influencers helps me stay up-to-date with the latest trends. It's a must-have for anyone looking to switch up their style.`,
    },
    {
      name: 'Sophia Martinez',
      desc: `I absolutely love Hairpaca! As someone who loves experimenting with new hairstyles but is always hesitant to make a big change, this platform is a game-changer. I can try on different styles from my favorite influencers without any commitment. The weekly curated collection is a fantastic bonus!`,
    },
    {
      name: 'Michael Brown',
      desc: `Saving money while still getting that top stylist look is why I love Hairpaca. I used to spend so much on haircuts trying to find the right style. Now, I can experiment with different looks for a fraction of the cost. It's fast, easy, and budget-friendly.`,
    },
  ];

  useEffect(() => {
    const container = containerRef.current;
    const scrollStep = 1;
    const scrollInterval = 30;
    const scroll = () => {
      container.scrollLeft += scrollStep;
      if (container.scrollLeft + container.clientWidth >= container.scrollWidth) {
        container.scrollLeft = 0;
      }
    };
    const intervalId = setInterval(scroll, scrollInterval);
    // 清理定时器
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <div className={styles.content}>
      <div>
        <span>{t('User Showcase & Reviews')}</span>
      </div>

      <BaseLinearContent>
        <div className={styles.container} ref={containerRef}>
          {dataList.map((item, index) => {
            return (
              <div className={styles.cardContainer} key={index + item.name}>
                <div className={styles.contentInfo}>
                  <div className={styles.leftContainer}>
                    <Image
                      src={`/images/hairstyle/${item?.name}.png`}
                      alt=""
                      width={260}
                      height={210}
                    />
                  </div>

                  <div className={styles.rightContainer}>
                    <div className={styles.userContainer}>
                      <Image
                        style={{ borderRadius: '25px' }}
                        src={`/images/layouts/hairstyle-logo.png`}
                        alt=""
                        width={30}
                        height={30}
                      />
                      <span>{item.name}</span>
                    </div>
                    <NameStarContent index={5} />
                    <p className={styles.desc}>{item.desc}</p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </BaseLinearContent>
    </div>
  );
};

export default ReviewsContent;
